angular.module("app")
    .directive("cTemp", function ($rootScope, _mapFieldService, _view, gettextCatalog, _logicService) {
        return {
            restrict: "E",
            template: "{{x}}<small>{{units}}</small>",
            scope: {
                value: '@value'
            },
            link: function ($scope) {
                if ($scope.value == 'n/a') {
                    $scope.x = gettextCatalog.getString('n/a');
                } else {
                    if (_view.getCurrentUser().tempScale == "F") {
                        $scope.x = _logicService.round(32 + $scope.value * 1.8, 0);
                        $scope.units = gettextCatalog.getString('℉');
                    } else {
                        $scope.x = _logicService.round($scope.value);
                        $scope.units = gettextCatalog.getString('℃');
                    }
                }
            }
        }
    });